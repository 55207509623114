<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Below is a TLC plate of a reaction of DHZ and NaBH4. Calculate the Rf value of the top,
        middle and bottom spot. The solvent front is 4.3 cm.
      </p>

      <p class="mb-4">
        <v-img style="max-width: 200px" src="/img/assignments/UCIrvine/TLCPlateDHZ.png" />
      </p>

      <calculation-input
        v-model="inputs.input1"
        prepend-text="$\text{Top}:$"
        :disabled="!allowEditing"
        class="mb-3"
      />

      <calculation-input
        v-model="inputs.input2"
        prepend-text="$\text{Middle}:$"
        :disabled="!allowEditing"
        class="mb-3"
      />

      <calculation-input
        v-model="inputs.input3"
        prepend-text="$\text{Bottom}:$"
        :disabled="!allowEditing"
        class="mb-3"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput.vue';
import StembleLatex from '../StembleLatex.vue';

export default {
  name: 'ChemUCI51PLFS1Q1',
  components: {
    StembleLatex,
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
      },
    };
  },
};
</script>
<style scoped></style>
